import { _axios } from "interceptor/http-config";

export const _AuthApi = {
  login: async (data) => {
    return _axios.post("/login", data).then((res) => {
      _AuthApi.storeToken(res.data?.data?.access_token);
      _AuthApi.storeIsAdmin(res.data?.data?.is_admin);
      res.data?.data?.subdomain &&
        _AuthApi.storeSubDomain(res.data?.data?.subdomain);
    });
  },

  storeToken: (sookler_token) => {
    localStorage.setItem("sookler_token", sookler_token);
  },
  storeIsAdmin: (is_admin) => {
    localStorage.setItem("is_admin", is_admin);
  },
  storeSubDomain: (subdomain) => {
    localStorage.setItem("subdomain", subdomain);
  },

  getToken: () => localStorage.getItem("sookler_token"),
  getIsAdmin: () => localStorage.getItem("is_admin"),
  getSubDomain: () => localStorage.getItem("subdomain"),

  destroyToken: () => {
    localStorage.removeItem("sookler_token");
    localStorage.removeItem("is_admin");
    localStorage.removeItem("subdomain");
    window.location.reload();
  },

  sendCode: async (data) => {
    return _axios.post("/send-code", data);
  },
  verifyCode: async (data, config) => {
    return _axios.post("/check-code", data, {
      headers: config.headers,
    });
  },
  resetPassword: async (data) => {
    return _axios.post("/reset-password", data);
  },
  resendCode: async (data) => {
    return _axios.post("/resend_code", data);
  },
  updateProfile: async (formData) => {
    return _axios.post("/admins/profile", formData);
  },
};
